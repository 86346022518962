<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Add Items File
            </h2>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mt-2 mb-0">
                <v-row no-gutters>
                    <v-col cols="12" v-if="partNumber">
                        <v-text-field
                            v-model="partNumber"
                            prepend-icon="mdi-barcode"
                            prefix="Part number: "
                            class="mr-xl-2 mr-lg-2 mr-md-2"
                            readonly
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="partNumber">
                        <v-file-input
                            chips
                            label="File"
                            v-model="file"
                            :rules="[rules.required]"
                            required
                            prepend-icon="mdi-paperclip"
                            accept=".pdf,.dxf,.stp"
                        ></v-file-input>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-file-input
                            chips
                            label="File"
                            v-model="file"
                            :rules="[rules.required]"
                            required
                            prepend-icon="mdi-paperclip"
                            accept=".xls,.xlsx"
                        ></v-file-input>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                rounded
                color="primary"
                class="mb-5 mt-n4"
                @click="selectMethod"
                :disabled="!valid"
                :loading="loading"
            >
                ADD FILES
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import * as XLSX from 'xlsx'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import { saveFile } from '@/services/storage/saveFile.js'

export default {
    name: 'AddItemFiles',
    props: {
        partNumber: String,
        partNumbers: {
            type: Array,
            required: true,
        },
        workOrder: {
            type: Object,
            required: true,
        },
        selectedItem: {
            type: Object,
            default: () => undefined,
        },
        settingProcesses: {
            type: Object,
            default: () => ({}),
        },
        settingFinish: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        file: undefined,
        rules: {
            required: v => !!v || 'The value is required',
        },
        newItems: [],
        dataCSV: undefined,
        sheetData: undefined,
        extensions: 'pdf,dxf,stp',
        companyId: JSON.parse(localStorage.getItem('company')),
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        async selectMethod() {
            if (this.partNumber) {
                await this.addFile()
            } else {
                await this.readCSV()
            }
        },
        async addFile() {
            try {
                this.loading = true
                const lastIndex = this.file.name.lastIndexOf('.')
                if (
                    !this.extensions.includes(
                        this.file.name.split('.').pop()
                    ) ||
                    this.partNumber.toLowerCase() !=
                        this.file.name.substring(0, lastIndex).toLowerCase()
                ) {
                    throw new Error(
                        "The part number doesn't match the file name"
                    )
                }
                await saveFile(
                    this.file,
                    `${this.companyId}/workOrders/${this.workOrder.id}/items`
                )
                const item = this.workOrder.items.find(
                    element =>
                        element.partNumber.toLowerCase() ==
                        this.file.name.substring(0, lastIndex).toLowerCase()
                )
                if (!item.files || item.files.length == 0) {
                    item.files = [this.file.name]
                } else {
                    item.files = [
                        ...item.files.filter(
                            file =>
                                file.toLowerCase() !=
                                this.file.name.toLowerCase()
                        ),
                        this.file.name,
                    ]
                }
                await API.addFilesToItems({
                    workOrderId: this.workOrder.id,
                    items: [{ itemId: item.id, files: item.files }],
                })
                this.$emit('addFiles', [{ itemId: item.id, files: item.files }])
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        async completeReading(results) {
            try {
                this.loading = true
                this.dataCSV = results
                await this.addFiles()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async readCSV() {
            try {
                this.loading = true
                if (
                    this.file.name.endsWith('.xls') ||
                    this.file.name.endsWith('.xlsx')
                ) {
                    const data = await this.file.arrayBuffer()
                    const workbook = XLSX.read(data)
                    const sheetName = workbook.SheetNames[0]
                    const xlsData = XLSX.utils.sheet_to_json(
                        workbook.Sheets[sheetName],
                        { raw: false }
                    )
                    this.sheetData = xlsData
                    await this.addFiles()
                } else {
                    throw new Error('Unsupported file format')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async addFiles() {
            try {
                this.loading = true
                var partNumbersNotFound = []
                var items = this.sheetData
                // validate part numbers
                for (const itemIndex in items) {
                    const partNumber = this.partNumbers.find(
                        pn => pn.code == items[itemIndex]['Part Number']
                    )
                    if (!partNumber) {
                        partNumbersNotFound.push(
                            items[itemIndex]['Part Number']
                        )
                    } else if (
                        this.workOrder.items.find(
                            i => i.partNumberId == partNumber.id
                        )
                    ) {
                        items.splice(itemIndex, 1)
                    }
                }
                if (partNumbersNotFound.length > 0) {
                    this.$emit('openPartNumbersNotFound', partNumbersNotFound)
                    return null
                }
                for (const item of items) {
                    const {
                        'Part Number': partNumber,
                        'Finish & UC Code': finishAndUCCodeName,
                        Description: description,
                        Mass: mass,
                        'Painting Area ': paintingArea,
                        QTY: qty,
                        NOTES: notes,
                        ...otherProps
                    } = item
                    const excludedKeys = ['Mass (lbmass)', 'PA (mm^2)']
                    let processes = Object.keys(otherProps).filter(
                        key => !excludedKeys.includes(key)
                    )
                    processes = processes.map(process => process.trim())

                    const { id: partNumberId } = this.partNumbers.find(
                        pn => pn.code == partNumber
                    )
                    const finishIndex = this.settingFinish.finishes.findIndex(
                        fc => fc.name == finishAndUCCodeName
                    )
                    let finishAndUCCode = ''
                    if (finishIndex > -1) {
                        finishAndUCCode = this.settingFinish.finishes[
                            finishIndex
                        ].id
                    } else {
                        throw new Error(
                            `${partNumber} does not have a valid Finish & UC Code`
                        )
                    }
                    const newItem = await API.createWorkOrderItem({
                        workOrderId: this.workOrder.id,
                        partNumberId,
                        finishAndUCCode: finishAndUCCode,
                        description,
                        mass: parseFloat(
                            mass.split(',').length == 2
                                ? mass.replace(',', '.')
                                : mass
                        ),
                        paintingArea:
                            Number(
                                paintingArea.split(',').length == 2
                                    ? paintingArea.replace(',', '.')
                                    : paintingArea
                            ) || 0,
                        processes: this.settingProcesses.processes
                            .filter(process =>
                                processes.find(
                                    e =>
                                        e.toLowerCase() ==
                                        process.name.toLowerCase()
                                )
                            )
                            .map(process => process.id),
                        notes,
                        quantity: Number(qty),
                        settingProcessesId: this.settingProcesses.id,
                        settingFinishId: this.settingFinish.id,
                    })
                    this.newItems.push(newItem)
                }
                this.$emit('addItems', this.newItems)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
